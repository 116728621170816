import { useContext } from "react";
import Table from "../../Components/common/Table";
import { PermissionsContext } from "../../context";

const Tickets = () => {
  const { permissions } = useContext(PermissionsContext);
  const cols = {
    name: { label: "Name", value: "name", hidden: false },
    mobile: { label: "Mobile", value: "mobile", hidden: false },
    message: { label: "Message", value: "message", hidden: false },
    actions: {
      label: "Actions",
      value: "actions",
      actions: {},
      hidden: false,
    },
  };

  const tools = {
    add: permissions.includes("ticket.create"),
    search: true,
    download: permissions.includes("ticket.download"),
    filter: true,
    filter_cols: true,
  };

  return (
    <Table path={"tickets"} tools={tools} cols={cols}>
      <Table.OuterContainer>
        <Table.InnerContainer>
          <Table.Tools />
          <Table.HeadContainer>
            <Table.Head />
            <Table.Body />
          </Table.HeadContainer>
        </Table.InnerContainer>
        <Table.Footer />
      </Table.OuterContainer>
    </Table>
  );
};

export default Tickets;
