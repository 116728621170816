import { useEffect } from "react";

export default function useModalTransition(elementRef, iconRef, callback) {
  useEffect(() => {
    let iconRefValue = null;
    const handleClickOutside = (event) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        callback(false);
      }
    };

    const handleClickIcon = () => {
      callback((show) => !show);
    };

    if (iconRef.current && elementRef.current) {
      iconRef.current.addEventListener("click", handleClickIcon);
      document.addEventListener("click", handleClickOutside, true);
      iconRefValue = iconRef.current;
      return () => {
        iconRefValue.removeEventListener("click", handleClickIcon);
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [callback, elementRef, iconRef]);
}
