class Permissions {
    constructor({ id, name, code, children }) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.children = children;
    }
}


export {
    Permissions
}