import { useCallback, useContext, useEffect, useState } from "react";
import Form from "../Form";
import { insertNewRow, updateNewRow } from "../../../common/Table/methods";
import {
  Checkbox,
  SelectInput,
  TextArea,
  TextInput,
  UploadImage,
} from "../../../common";
import { EnumsContext } from "../../../../context";

const AddSettings = ({
  settingId,
  selectedRow,
  show,
  setShow,
  updated,
  setUpdated,
  handleInputChange,
  setRefreshRows,
}) => {
  const [setting, setSetting] = useState([]);
  const {
    enums: { InputType },
  } = useContext(EnumsContext);
  const [typeOptions, setTypeOptions] = useState([]);
  const [defaultTypeOption, setDefaultTypeOption] = useState([]);
  const [loadingType, setLoadingType] = useState(true);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (settingId) {
        updateNewRow(updated, "settings", settingId);
      } else {
        insertNewRow(updated, "settings");
      }
      setShow(false);
      setRefreshRows(true);
    },
    [settingId, setShow, setRefreshRows, updated]
  );
  useEffect(() => {
    if (selectedRow) {
      setSetting(selectedRow);
    }
  }, [selectedRow, show]);

  useEffect(() => {
    if (!show) {
      setSetting([]);
      setUpdated([]);
    }
  }, [setUpdated, show]);

  useEffect(() => {
    if (show && selectedRow) {
      Object.keys(InputType).forEach((item) => {
        if (parseInt(InputType[item]) === parseInt(selectedRow.input_type)) {
          setDefaultTypeOption(item);
        }
      });
    }
  }, [show, selectedRow]);

  return (
    <Form show={show} setShow={setShow} onSubmit={onSubmit}>
      <Form.Container>
        <Form.Content>
          <Form.Row>
            {(() => {
              switch (defaultTypeOption) {
                case "TextBox":
                  return (
                    <TextInput
                      key={selectedRow?.value}
                      name={"value"}
                      label={selectedRow?.name}
                      defaultValue={selectedRow?.value}
                      onChange={handleInputChange}
                    />
                  );
                case "TextArea":
                  return (
                    <TextArea
                      key={selectedRow?.value}
                      name={"value"}
                      label={selectedRow?.name}
                      defaultValue={selectedRow?.value}
                      onChange={handleInputChange}
                    />
                  );
                case "Image":
                  return (
                    <UploadImage
                      beforeLabel={selectedRow?.name}
                      name={"value"}
                      onChange={handleInputChange}
                    />
                  );
                case "CheckBox":
                  return (
                    <Checkbox
                      name={"value"}
                      key={parseInt(selectedRow?.value) === 1}
                      beforeLabel={selectedRow?.name}
                      defaultChecked={parseInt(selectedRow?.value) === 1}
                      onChange={handleInputChange}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Form.Row>
        </Form.Content>
        <Form.Footer />
      </Form.Container>
    </Form>
  );
};

export default AddSettings;
