import { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox } from "../../../common";
import Form from "../Form";
import { updateNewRow } from "../../../common/Table/methods";

const AddProvider = ({
  providerId,
  selectedRow,
  show,
  setShow,
  updated,
  setUpdated,
  handleInputChange,
  setRefreshRows,
}) => {
  const [provider, setProvider] = useState([]);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (providerId) {
        updateNewRow(updated, "providers", providerId);
      }
      setShow(false);
      setRefreshRows(true);
    },
    [providerId, setShow, setRefreshRows, updated]
  );

  const verifiedRef = useRef();
  const suspendedRef = useRef();
  const banedRef = useRef();

  useEffect(() => {
    if (!show) {
      setProvider([]);
      setUpdated([]);
    }
  }, [setUpdated, show]);

  useEffect(() => {
    if (selectedRow) {
      setProvider(selectedRow.provider);
      setUpdated({
        is_verified: selectedRow.provider?.is_verified ? 1 : 0,
        is_suspended: selectedRow.provider?.is_suspended ? 1 : 0,
        is_baned: selectedRow.provider?.is_baned ? 1 : 0,
      });
    }
  }, [selectedRow]);

  return (
    <Form show={show} setShow={setShow} onSubmit={onSubmit}>
      <Form.Container>
        <Form.Content title={"Edit provider"}>
          <Form.Row className="cols-span-6">
            <div className="col-span-1 sm:col-span-6 space-y-2 pt-5">
              <div className="flex justify-between text-placeholder-color">
                <span>Verified</span>
                <Checkbox
                  ref={verifiedRef}
                  name={"is_verified"}
                  checked={updated.is_verified || false}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-between text-placeholder-color">
                <span>Suspended</span>
                <Checkbox
                  ref={suspendedRef}
                  name={"is_suspended"}
                  checked={updated.is_suspended || false}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-between text-placeholder-color">
                <span>Baned</span>
                <Checkbox
                  ref={banedRef}
                  name={"is_baned"}
                  checked={updated.is_baned || false}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </Form.Row>
        </Form.Content>
        <Form.Footer />
      </Form.Container>
    </Form>
  );
};
export default AddProvider;
