import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Form from "../Form";
import { Checkbox } from "../../../common";
import { Employee, Permissions, Role } from "../../../../classes";
import { query } from "../../../../utils";
import { toast } from "react-toastify";
import useIsMount from "../../../../hooks/useIsMount";

const SetPermissions = ({
  userId,
  show,
  setShow,
  updated,
  setUpdated,
  handleInputChange,
  setRefreshRows,
  selectedRow,
}) => {
  const [roles, setRoles] = useState([]);

  const [updatedRoles, setUpdatedRoles] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState([]);
  const [isCheck, setIsCheck] = useState([]);

  const [userRoles, setUserRoles] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  const [loading, setLoading] = useState(true);
  const isMount = useIsMount();

  useEffect(() => {
    // Get Roles
    const fetchData = async () => {
      try {
        const {
          data: { data },
        } = await query(`/api/dashboard/employees/${userId}`);
        const employee = new Employee(data.Employee);
        setUserRoles(employee.roles);
        setUserPermissions(employee.permissions);
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    if (userId && show && !isMount) {
      fetchData();
    }
  }, [isMount, show, userId]);

  useEffect(() => {
    // Get Roles
    const fetchData = async () => {
      try {
        const {
          data: { data },
        } = await query("/api/dashboard/roles");
        setRoles(data.RoleCollection.Roles.map((role) => new Role(role)));
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    if (userId && show) {
      fetchData();
    }
  }, [userId, show]);

  useEffect(() => {
    // Get All Permissions
    const fetchData = async () => {
      try {
        const {
          data: { data },
        } = await query("/api/dashboard/permissions");
        setPermissions(data.Permissions.map((ele) => new Permissions(ele)));
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    if (userId && show) {
      fetchData();
    }
  }, [userId, show]);

  useEffect(() => {
    if (!isMount) {
      let perms = [];
      roles.forEach((role) => {
        if ((userRoles || updatedRoles).includes(role.id)) {
          perms = [...role.permissions];
        }
      });
      setIsCheck([...new Set([...perms, ...userPermissions, ...isCheck])]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, userPermissions, userRoles]);

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setIsCheck([...new Set([...isCheck, parseInt(value)])]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(value)));
    }
  };

  const handleSelectAll = (e) => {
    const { value, checked } = e.target;
    setIsCheckAll([...isCheckAll, parseInt(value)]);
    permissions.forEach((li) => {
      if (li.id === parseInt(value) && checked) {
        let insideChildren = [];
        if (li.children) {
          li.children.forEach((child) =>
            child.children.forEach((child) => insideChildren.push(child.id))
          );
        }
        setIsCheck((oldCheck) => {
          return [
            ...new Set([
              parseInt(value),
              ...oldCheck,
              ...li.children.map((child) => child.id),
              ...insideChildren,
            ]),
          ];
        });
      }
    });
    if (!checked) {
      let perms = [...isCheck];
      permissions.forEach((item) => {
        if (item.id === parseInt(value)) {
          item.children.forEach((child) => {
            let index = perms.indexOf(child.id);
            perms.splice(index, 1);
          });
        }
      });
      setIsCheck(perms.filter((item) => item !== parseInt(value)));
    }
  };

  const handleRoleChange = ({ target: { value, checked } }) => {
    if (checked) {
      setUpdatedRoles([...new Set([...updatedRoles, parseInt(value)])]);
      roles.forEach((element) => {
        if (element.id === parseInt(value)) {
          setIsCheck([...new Set([...isCheck, ...element.permissions])]);
        }
      });
    } else {
      let perms = [...isCheck];
      roles.forEach((element) => {
        if (element.id === parseInt(value)) {
          element.permissions.forEach((item) => {
            let index = perms.indexOf(item.id);
            perms.splice(index, 1);
          });
        }
      });
      setIsCheck([...perms]);
      setUpdatedRoles(updatedRoles.filter((role) => parseInt(value) !== role));
    }
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const form = new FormData();
        form.append("_method", "PUT");
        if (!permissions) {
          form.append("permissions[]", []);
        }
        if (!roles) {
          form.append("roles[]", []);
        }
        for (let index = 0; index < isCheck.length; index++) {
          form.append("permissions[]", isCheck[index]);
        }
        for (let index = 0; index < updatedRoles.length; index++) {
          form.append("roles[]", updatedRoles[index]);
        }
        await query(
          `/api/dashboard/employees/${userId}`,
          "post",
          form,
          "multipart/form-data"
        );
        Swal.fire("Role updated successfully!", "", "success");
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
      setShow(false);
      setRefreshRows(true);
    },
    [isCheck, setRefreshRows, setShow, updatedRoles, userId]
  );

  useEffect(() => {
    if (!show && !isMount) {
      setIsCheck([]);
      setPermissions([]);
      setRoles([]);
      setUserPermissions([]);
    }
  }, [isMount, show]);

  return (
    <Form show={show} setShow={setShow} isLoading={loading} onSubmit={onSubmit}>
      <Form.Container>
        <Form.Content>
          <Form.Row>
            <h1 className="text-placeholder-color border-b border-black dark:border-white pb-2">
              Edit Role
            </h1>
          </Form.Row>
          <Form.Row className="grid grid-cols-2 gap-5 py-5 pr-5">
            <div className="max-h-100 max-w-100 bg-white dark:bg-gray-800 p-3 rounded-primary">
              <h1 className="text-placeholder-color">Select Roles</h1>
              <div className="grid grid-cols-2 gap-2 pt-2">
                {roles &&
                  roles?.map(({ id, name }) => {
                    return (
                      <Checkbox
                        key={
                          updatedRoles.includes(parseInt(id)) ||
                          userRoles.includes(parseInt(id))
                        }
                        // checked={
                        //   updatedRoles.includes(parseInt(id)) ||
                        //   userRoles.includes(parseInt(id))
                        // }
                        defaultChecked={
                          updatedRoles.includes(parseInt(id)) ||
                          userRoles.includes(parseInt(id))
                        }
                        afterLabel={name}
                        value={parseInt(id)}
                        onChange={handleRoleChange}
                      />
                    );
                  })}
              </div>
            </div>
          </Form.Row>
          <Form.Row className="flex flex-col justify-between w-full">
            <div className="grid grid-cols-2 gap-5 pt-5 pr-5">
              {permissions &&
                permissions?.map(({ id, name, code, children }) => {
                  return (
                    <div
                      key={id}
                      className="max-h-100 max-w-100 bg-white dark:bg-gray-800 p-3 rounded-primary"
                    >
                      <div className="flex gap-2 border-b text-placeholder-color w-full pb-2">
                        <Checkbox
                          key={isCheck.includes(parseInt(id))}
                          onClick={handleSelectAll}
                          afterLabel={name}
                          // checked={isCheck.includes(parseInt(id))}
                          defaultChecked={isCheck.includes(parseInt(id))}
                          value={parseInt(id)}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-2 pt-2">
                        {children &&
                          children?.map(({ id, name, code, children }) => {
                            return (
                              <div key={id}>
                                <Checkbox
                                  key={isCheck.includes(parseInt(id))}
                                  // checked={isCheck.includes(parseInt(id))}
                                  defaultChecked={isCheck.includes(
                                    parseInt(id)
                                  )}
                                  afterLabel={name}
                                  value={parseInt(id)}
                                  onClick={handleClick}
                                />
                                {children &&
                                  children.map(
                                    ({ id, name, code, children }) => {
                                      return (
                                        <div key={id}>
                                          <Checkbox
                                            key={isCheck.includes(parseInt(id))}
                                            // checked={isCheck.includes(
                                            //   parseInt(id)
                                            // )}
                                            defaultChecked={isCheck.includes(
                                              parseInt(id)
                                            )}
                                            afterLabel={name}
                                            value={parseInt(id)}
                                            onClick={handleClick}
                                          />
                                          {children &&
                                            children.map(
                                              ({
                                                id,
                                                name,
                                                code,
                                                children,
                                              }) => {
                                                return (
                                                  <Checkbox
                                                    key={isCheck.includes(
                                                      parseInt(id)
                                                    )}
                                                    // checked={isCheck.includes(
                                                    //   parseInt(id)
                                                    // )}
                                                    defaultChecked={isCheck.includes(
                                                      parseInt(id)
                                                    )}
                                                    afterLabel={name}
                                                    value={parseInt(id)}
                                                    onClick={handleClick}
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Form.Row>
        </Form.Content>
        <Form.Footer />
      </Form.Container>
    </Form>
  );
};
export default SetPermissions;
