class Ticket {
  constructor({ id, name, mobile, message }) {
    this.id = id;
    this.name = name;
    this.mobile = mobile;
    this.message = message;
  }
}

export { Ticket };
