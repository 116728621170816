import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import useScrollPosition from "../../hooks/useScrollPosition";
import { saveSidebar } from "../../utils/saveSidebar";
import IMAGES from "./../../assets";
import useThemeDetector from "../../hooks/useThemeDetector";
import { useMousePosition } from "../../hooks/useMousePosition";

const Sidebar = ({ sidebarItems, setSideBarWidth }) => {
  const { pathname } = useLocation();
  const [type, setType] = useState("full");
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    setType(localStorage.getItem("sidebar") || "full");
  }, []);

  const dropdownClicked = (path) => {
    const dropdown = document.getElementById(path);
    if (dropdown.classList.contains("show-modal")) {
      dropdown.classList.add("hide-modal");
      dropdown.classList.remove("show-modal");
    } else {
      dropdown.classList.add("show-modal");
      dropdown.classList.remove("hide-modal");
    }
  };

  useEffect(() => {
    sidebarItems?.forEach((item) => {
      item.children &&
        item.children?.forEach((child) => {
          if (child.path === pathname.substring(1)) {
            const dropdown = document.getElementById(item.path);
            dropdown.classList.add("show-modal");
            dropdown.classList.remove("hide-modal");
          }
        });
    });
  }, [pathname, sidebarItems]);

  const isDarkTheme = useThemeDetector();

  const coordinates = useMousePosition();

  return (
    <>
      <aside
        className={`${
          scrollPosition >= 30 && "shadow-none"
        } z-30 transition-[width] ease-in-out fixed shadow-xl ${
          type === "full" && "w-[12rem]"
        } ${type === "collabsed" && "w-[4.2rem]"} float-left`}
        aria-label="Sidebar"
      >
        <div className="invisible-scroll h-[100vh] overflow-y-scroll px-4 py-4 rounded-r bg-[white] dark:bg-gray-800">
          <Link
            to={"dashboard"}
            className="flex items-center mb-5 overflow-hidden"
          >
            {type === "full" ? (
              isDarkTheme || localStorage.getItem("color-theme") === "dark" ? (
                <img
                  src={IMAGES.light_full_logo.src}
                  className="delay-50 w-[9rem] max-w-none"
                  alt={IMAGES.light_full_logo.src}
                />
              ) : (
                <img
                  src={IMAGES.dark_full_logo.src}
                  className="delay-50 w-[9rem] max-w-none"
                  alt={IMAGES.dark_full_logo.src}
                />
              )
            ) : isDarkTheme ||
              localStorage.getItem("color-theme") === "dark" ? (
              <img
                src={IMAGES.light_collabsed_logo.src}
                className="delay-50 w-[1.8rem] max-w-none"
                alt={IMAGES.light_collabsed_logo.src}
              />
            ) : (
              <img
                src={IMAGES.dark_collabsed_logo.src}
                className="delay-50 w-[1.8rem] max-w-none"
                alt={IMAGES.dark_collabsed_logo.src}
              />
            )}
          </Link>
          <ul className="space-y-2">
            {Object.keys(sidebarItems).map((ele) => {
              const { path, label, tooltip, icon, children, hidden } =
                sidebarItems[ele];
              return !children ? (
                !hidden && (
                  <li key={path} className="group">
                    <Link
                      to={path}
                      className={`flex ${
                        type !== "full" && "justify-center"
                      } items-center p-2 rounded-lg text-[#ADB5BD] hover:text-primary-color hover:bg-hover-bg-color dark:hover:bg-primary-color dark:hover:text-[white]
                    ${
                      pathname === `/${path}` &&
                      "text-primary-color bg-hover-bg-color dark:bg-primary-color dark:text-white"
                    }`}
                    >
                      {type === "full" ? (
                        <>
                          <FontAwesomeIcon icon={icon} />
                          <span className="capitalize ml-3 rtl:mr-3">
                            {label}
                          </span>
                        </>
                      ) : (
                        <div className=" flex items-center group">
                          <FontAwesomeIcon icon={icon} />
                          <div
                            role="tooltip"
                            className="absolute left-[4rem] rtl:left-auto rtl:right-10 max-w-auto hidden group-hover:flex
                          inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700"
                            style={{
                              top: coordinates.y + "px",
                            }}
                          >
                            {tooltip}
                          </div>
                        </div>
                      )}
                    </Link>
                  </li>
                )
              ) : (
                <li key={path}>
                  <button
                    type="button"
                    className={`group dark:text-[#ADB5BD]
                        text-[#ADB5BD] rounded-lg 
                        group flex items-center hover:text-primary-color w-full p-2 text-base transition duration-75`}
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                    onClick={() => dropdownClicked(path)}
                  >
                    <FontAwesomeIcon
                      className="dark:group-hover:text-white group-hover:text-primary-color dark:group-hover:text-white"
                      icon={icon}
                    />
                    {type === "full" ? (
                      <>
                        <span className="flex-1 ml-3 text-left group-hover:text-primary-color dark:group-hover:text-white">
                          {label}
                        </span>
                        <FontAwesomeIcon
                          className="group-hover:text-primary-color dark:group-hover:text-white"
                          icon={faAngleDown}
                        />
                      </>
                    ) : (
                      <div
                        role="tooltip"
                        style={{
                          top: coordinates.y + "px",
                        }}
                        className={`absolute hidden left-[4rem] rtl:left-auto rtl:right-10 max-w-auto group-hover:flex
                          inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700`}
                      >
                        {tooltip}
                      </div>
                    )}
                  </button>
                  <ul
                    id={path}
                    className={`hide-modal rounded-b-lg max-h-[30rem] w-full transition-all ease`}
                  >
                    {children.map(({ path, label, tooltip, icon, hidden }) => {
                      return (
                        !hidden && (
                          <li key={path} className="group my-2">
                            <Link
                              to={path}
                              className={`flex items-center p-2 
                                  ${type === "full" && "pl-5"}
                                  rounded-lg text-[#ADB5BD] hover:text-primary-color hover:bg-hover-bg-color dark:hover:bg-primary-color dark:hover:text-[white] transition duration-75 hover:bg-hover-bg-color hover:text-primary-color dark:text-white
                          ${
                            pathname === `/${path}` &&
                            "text-primary-color bg-hover-bg-color dark:bg-primary-color dark:text-white"
                          }`}
                            >
                              {type === "full" ? (
                                <>
                                  <FontAwesomeIcon icon={icon} />
                                  <span className="capitalize ml-3 rtl:mr-3">
                                    {label}
                                  </span>
                                </>
                              ) : (
                                <div className="flex items-center">
                                  <FontAwesomeIcon icon={icon} />
                                  <div
                                    role="tooltip"
                                    className="absolute left-[4rem] rtl:left-auto rtl:right-10 max-w-auto hidden group-hover:flex
                                    inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700"
                                    style={{
                                      top: coordinates.y + "px",
                                    }}
                                  >
                                    {tooltip}
                                  </div>
                                </div>
                              )}
                            </Link>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
          <div
            className={`${
              type === "full" && "left-[92%]"
            } cursor-pointer left-[77%] z-20 dark:bg-gray-800 p-2 rounded-[50%] absolute bottom-[15px]  flex justify-center `}
          >
            {type === "full" ? (
              <FontAwesomeIcon
                onClick={() => {
                  setSideBarWidth(true);
                  setType("collabsed");
                  saveSidebar("collabsed");
                }}
                className="text-[#ADB5BD] hover:text-primary-color h-[1.1rem] dark:text-white"
                icon={faArrowLeft}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => {
                  setSideBarWidth(false);
                  setType("full");
                  saveSidebar("full");
                }}
                className="text-[#ADB5BD] hover:text-primary-color h-[1.1rem] dark:text-white"
                icon={faArrowRight}
              />
            )}
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
