import darkCollabsedLogo from "./dark-collapsed-logo.png";
import lightCollabsedLogo from "./light-collapsed-logo.png";
import darkFullLogo from "./dark-full-logo.png";
import lightFullLogo from "./light-full-logo.png";

import excel from "./excel.svg";
import loginCol2 from "./login-col-2.svg";
import pdf from "./pdf.svg";
import react from "./react.svg";
import tableImg from "./table-img.webp";

const IMAGES = {
  dark_full_logo: {
    src: darkFullLogo,
    alt: "Dark Full Logo",
  },
  light_full_logo: {
    src: lightFullLogo,
    alt: "Light Full Logo",
  },
  dark_collabsed_logo: {
    src: darkCollabsedLogo,
    alt: "Dark Collapsed Logo",
  },
  light_collabsed_logo: {
    src: lightCollabsedLogo,
    alt: "Light Collapsed Logo",
  },
  excel: {
    src: excel,
    alt: "",
  },
  login_col_2: {
    src: loginCol2,
    alt: "",
  },
  pdf: {
    src: pdf,
    alt: "",
  },
  react: {
    src: react,
    alt: "",
  },
  table_img: {
    src: tableImg,
    alt: "",
  },
};

export default IMAGES;
