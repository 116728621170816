import { useCallback, useEffect, useRef, useState } from "react";
import { query } from "../../../../utils";
import { Checkbox, SelectInput, TextInput, UploadImage } from "../../../common";
import { Employee, SelectCity, SelectCountry } from "../../../../classes";
import Form from "../Form";
import { insertNewRow, updateNewRow } from "../../../common/Table/methods";
import { toast } from "react-toastify";

const AddEmployee = ({
  employeeId,
  selectedRow,
  show,
  setShow,
  updated,
  setUpdated,
  handleInputChange,
  setRefreshRows,
}) => {
  const [user, setUser] = useState({});

  const [countrySearch] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);

  const [citySearch, setCitySearch] = useState("");
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { data },
        } = await query(`/api/dashboard/employees/${employeeId}`, "get");
        setUser(new Employee(data.Employee));
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    if (employeeId && show) {
      fetchData();
    }
  }, [employeeId, show]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: {
            data: { Countries },
          },
        } = await query(
          `/api/dashboard/lists/countries?q=${countrySearch}&is_active=1`
        );
        const countriesArr = Countries.map((country) => {
          return new SelectCountry(country);
        });
        setCountryOptions(countriesArr);
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    let timer = setTimeout(() => {
      if (show) {
        fetchData();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [show, countrySearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: {
            data: { Cities },
          },
        } = await query(
          `/api/dashboard/lists/cities?q=${citySearch}&country_id=${
            updated.country_id || user.country_id
          }&is_active=1`
        );
        const cityArr = Cities.map((city) => {
          return new SelectCity(city);
        });
        setCityOptions(cityArr);
      } catch ({
        response: {
          data: { message },
        },
      }) {
        toast.error(<span>{message.join("\r\n")}</span>);
      }
    };
    let timer = setTimeout(() => {
      if (show && (user.country_id || updated.country_id)) {
        fetchData();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [citySearch, countryOptions, show, updated.country_id, user.country_id]);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (employeeId) {
        updateNewRow(updated, "employees", employeeId);
      } else {
        insertNewRow(updated, "employees");
      }
      // setShow(false);
      setRefreshRows(true);
    },
    [employeeId, setShow, updated, setRefreshRows]
  );

  useEffect(() => {
    if (selectedRow) {
      setUser(selectedRow);
      setUpdated({
        is_suspended: user.is_suspended ? 1 : 0,
        is_baned: user.is_baned ? 1 : 0,
      });
    }
  }, [selectedRow]);

  const nameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();

  useEffect(() => {
    if (!show) {
      setUser([]);
      setUpdated([]);
    }
  }, [setUpdated, show]);

  const [passwordError, setPasswordError] = useState(false);

  const onBlur = () => {
    if (
      updated.password === updated.password_confirmation ||
      (!updated.password_confirmation && updated.password)
    ) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  return (
    <Form show={show} setShow={setShow} onSubmit={onSubmit}>
      <Form.Container>
        <Form.Content title={"Add Employee"}>
          <Form.Row className="grid grid-cols-3 gap-5">
            <div className="col-span-3 sm:col-span-1">
              <TextInput
                ref={nameRef}
                key={user.name}
                name={"name"}
                label={"Name"}
                defaultValue={user.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-3 sm:col-span-1">
              <TextInput
                ref={emailRef}
                key={user.email}
                name={"email"}
                label={"Email"}
                defaultValue={user.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-3 sm:col-span-1">
              <TextInput
                ref={mobileRef}
                key={user.mobile}
                name={"mobile"}
                label={"Mobile"}
                defaultValue={user.mobile}
                onChange={handleInputChange}
              />
            </div>
          </Form.Row>
          <Form.Row className="grid grid-cols-2 gap-5">
            <div className="col-span-3 sm:col-span-1">
              <TextInput
                type={"password"}
                name={"password"}
                label={"Password"}
                onChange={handleInputChange}
                onBlur={onBlur}
              />
            </div>
            <div className="col-span-3 sm:col-span-1">
              <TextInput
                type={"password"}
                name={"password_confirmation"}
                label={"Confirm password"}
                onChange={handleInputChange}
                error={passwordError}
                errorMsg={"Password doesn't match"}
                onBlur={onBlur}
              />
            </div>
          </Form.Row>
          <Form.Row>
            <div className="grid grid-cols-2 gap-5">
              <SelectInput
                ref={countryRef}
                key={user?.Country?.name}
                name={"Country"}
                label={"Country"}
                options={countryOptions}
                onChange={(country) => {
                  setUpdated({
                    ...updated,
                    country_id: country.id,
                  });
                }}
                defaultValue={user?.Country && new SelectCountry(user?.Country)}
              />
              <SelectInput
                ref={cityRef}
                id={"city_input"}
                key={user?.City?.name}
                name={"City"}
                label={"City"}
                options={cityOptions}
                onChange={(city) => {
                  setCitySearch(city.name);
                  setUpdated({
                    ...updated,
                    city_id: city.id,
                  });
                }}
                defaultValue={
                  (user?.City && new SelectCity(user?.City)) ||
                  (updated?.City?.country_id && new SelectCity(updated?.City))
                }
              />
            </div>
          </Form.Row>
          <Form.Row className="col-span-2">
            <div className="grid grid-cols-2">
              <Checkbox
                name={"is_suspended"}
                beforeLabel={"Is Suspended"}
                checked={updated.is_suspended || false}
                onChange={handleInputChange}
              />
              <Checkbox
                name={"is_baned"}
                beforeLabel={"Is Baned"}
                checked={updated.is_baned || false}
                onChange={handleInputChange}
              />
            </div>
          </Form.Row>
        </Form.Content>
        <Form.Footer />
      </Form.Container>
    </Form>
  );
};

export default AddEmployee;
